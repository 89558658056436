export const firebaseConfig = {
  apiKey: "AIzaSyDwXEl2bwL0_NyiuqQy_tV6qUj40xQg-qE",
  authDomain: "my-staging.duals.app",
  databaseURL: "https://duals-values-staging.firebaseio.com/",
  projectId: "duals-staging",
  storageBucket: "public-duals-staging",
  messagingSenderId: "549759384133",
  appId: "1:549759384133:web:3f900cfd8880baf0f1412c",
  measurementId: "G-11D2LEY5MC",
};

export const appCheckSiteKey = "6LcLPdwaAAAAAKcXaE6_3U0IQz5_1FdcH8MtjgIC";
